import API from '../../js/api';

export default {
    namespaced: true,
    state: {
        user: null,
        isLoading: true,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        LOGIN_USER(state, status) {
            state.user.isAuthenticated = status;
        }
    },
    actions: {
        async getUser(context) {
            var user;
            try {
                user = await API.getUser();
            }
            catch {
                user = null;
            }
            if (user && user.isAuthenticated) {
                context.commit('SET_USER', user);
            } else {
                context.commit('SET_USER', null);
            }
        },
        async setUser(context, data) {
            let { status } = data;
            try {
                if (!context.state.user) {
                    var user = await API.getUser();
                } else {
                    user = context.state.user;
                }
                if (user.isAuthenticated) {
                    context.commit('SET_USER', user);
                }
                else if (status == false && user.isAuthenticated) {
                    user.isAuthenticated = false;
                    context.commit('SET_USER', user);
                }
            }
            catch {
                user = null;
            }
        },
    },
    getters: {
        isAuthenticated(state) {
            if (state.user && state.user.isAuthenticated) {
                return true;
            }
            return false;
        },
        user(state) {
            return state.user;
        }
    }
}